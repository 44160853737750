import { render, staticRenderFns } from "./Generico.vue?vue&type=template&id=3f19178a&scoped=true"
import script from "./Generico.vue?vue&type=script&lang=ts"
export * from "./Generico.vue?vue&type=script&lang=ts"
import style0 from "./Generico.vue?vue&type=style&index=0&id=3f19178a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f19178a",
  null
  
)

export default component.exports